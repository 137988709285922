import React, { useState } from 'react';
import { Container, Row, Col, FormControl, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './Subscribe.css';
import subscribeHero from '../../assets/images/subscribe.png'
import { IoArrowForward } from 'react-icons/io5';

function Subscribe() {
    const [emailInput, setEmailInput] = useState('');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const handleInputChange = (e) => {
        setEmailInput(e.target.value);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://hubibee.com/api/client_api/subscribe/index.php', { email: emailInput });
            setMessage(response.data.message);
            setShowMessage(true);
            setEmailInput(''); // Clear the input field
        } catch (error) {
            setMessage(error.response.data.message);
            setShowMessage(true);
        }

        // Hide the message after 3 seconds
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    };

    return (
        <div>
            <Container>
                <div
                    className="p-5 text-start bg-white my-5 rounded-pill"
                >
                    <Row>
                        <Col sm={12} lg={4} className='d-none d-lg-block d-md-none'>
                            <img src={subscribeHero} alt="testimonial-hero" className='subscribe-hero position-absolute' />
                        </Col>
                        <Col md={4} sm={12} lg={4} className=''>
                            <div className='text-lg-start text-md-start mb-2'>
                                <h2 className="my-0 text-uppercase text-brown fw-bold subscribe-text">Subscribe to our news letter!</h2>
                            </div>
                        </Col>
                        <Col md={8} sm={12} lg={4}  className='d-flex justify-content-center align-items-center'>
                            {message && <p className={`text-center mt-3`}>{message}</p>}

                            <Form onSubmit={handleSubscribe} className='w-100 position-relative'>
                                <FormControl
                                    style={{ padding: '10px', borderRadius: '30px', background: '#f6f4ee', color: '#000' }}
                                    aria-label="Email address"
                                    placeholder="Email address"
                                    value={emailInput}
                                    onChange={handleInputChange}
                                    className='custom-placeholder text-start text-brown position-relative'
                                    required
                                    type='email'
                                />
                                <Button type='submit' variant='light' className='position-absolute' style={{ background: '#FCFEB1', borderRadius: '21px', top: '2px', right: '3px', width: '42px', height: '42px', }}>
                                    <IoArrowForward />
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Subscribe;
