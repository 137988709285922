import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import { Link } from 'react-router-dom';

const CookiePolicy = () => {


  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
                   <div className='breadcrumb-item text-brown me-2'>Polcies</div>
          <div className='breadcrumb-item text-brown me-2'>1st September 2024</div>
          <div className='breadcrumb-item text-brown me-2'>Cookie Policy</div>
        </div>
      </div>
    );
  };

  return (
    <div className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={'Cookie Policy'} />
      <Container className="cookie-policy">
        <Row>
          <Col>
            <h2>1. Introduction</h2>
            <p>This Cookie Policy explains how Hubibee ("we," "us," "our") uses cookies and similar technologies on our website.</p>

            <h2>2. What Are Cookies?</h2>
            <h3>a. Cookies</h3>
            <p>Cookies are small text files stored on your device when you visit our website. They serve various purposes, including enhancing your experience.</p>

            <h3>b. Types of Cookies</h3>
            <ul>
              <li><strong>Essential Cookies:</strong> These are necessary for the website to function correctly.</li>
              <li><strong>Analytical Cookies:</strong> These help us analyze website traffic and improve our services.</li>
              <li><strong>Functional Cookies:</strong> These enhance your experience by remembering your preferences.</li>
              <li><strong>Advertising Cookies:</strong> These are used to display relevant ads.</li>
            </ul>

            <h2>3. How to Manage Cookies</h2>
            <p>You can manage your cookie preferences through your browser settings. Please note that disabling certain cookies may impact your experience on our website.</p>

            <h2>4. Contact Us</h2>
            <p>If you have any questions or concerns regarding our Cookie Policy, please contact us from the contact us page.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookiePolicy;
