import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import './Contactus.css';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import OurProducts from '../../components/Products/OurProducts';
import hive from '../../assets/images/hive.png';
import bee from '../../assets/images/bee.png';
import hivebag from '../../assets/images/hivebag.png';
import beenest from '../../assets/images/beenest.png';
import honeyjar from '../../assets/images/honeyjar.png';
import { IoArrowForwardOutline, IoCall, IoLocation, IoMail, IoMap } from 'react-icons/io5';

const Contactus = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        // Sending data as a POST request with formData included
        const response = await axios.post('https://hubibee.com/api/client_api/contactus/index.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // Ensure correct content type for form data
          },
        });

        if (response.data.status) {
          // Reset form data on successful submission
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
          setSubmitted(true);
        } else {
          setSubmitted(false); // Indicate failure
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitted(false); // Indicate failure
      }
    }
  };

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
          <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
          <div className='breadcrumb-item text-brown me-2'>About Us</div>
        </div>
      </div>
    );
  };



  return (
    <div className='sectionView'>
      <section className="p-b-10">
        <BreadcrumHeader route={Breadcrumb} title={'Contact Us'} />
        <Container className="px-lg-4 px-md-4 px-sm-4">
          <Row className="text-center">
            <Col md={6} sm={12} lg={4} className='d-flex flex-column flex-md-column align-items-start justify-content-center my-4'>
              <Card className='border-0 p-2 rounded-4 h-100 w-100  contactus-item'>
                <Card.Body >
                  <Card.Title className='fw-bold w-75 text-start'>Call Now</Card.Title>
                  <Card.Text className='text-start text-brown w-75 fw-100'> Phone Number</Card.Text>
                  <p className='text-start fw-bold text-dark'>9897969594</p>
                </Card.Body>
                <div className='contactus-wrap'></div>
                <button className="btn contactus-btn d-flex align-items-center justify-content-center rounded-5">
                  <IoCall className='contactus-arrow' color='#431E06' size={15} />
                </button>
              </Card>
            </Col>
            <Col md={6} sm={12} lg={4} className='d-flex flex-column flex-md-column align-items-start justify-content-center my-4'>
              <Card className='border-0 p-2 rounded-4 h-100 w-100  contactus-item'>
                <Card.Body >
                  <Card.Title className='fw-bold w-75  text-brown text-start'>Email Us</Card.Title>
                  <Card.Text className='text-start text-brown w-75 fw-100'> Our Email</Card.Text>
                  <p className='text-start fw-bold text-dark'>info@hubibee.com</p>
                </Card.Body>
                <div className='contactus-wrap'></div>
                <button className="btn contactus-btn d-flex align-items-center justify-content-center rounded-5">
                  <IoMail className='contactus-arrow' color='#431E06' size={15} />
                </button>
              </Card>
            </Col>
            <Col md={6} sm={12} lg={4} className='d-flex flex-column flex-md-column align-items-start justify-content-center my-4'>
              <Card className='border-0 p-2 rounded-4 h-100 w-100  contactus-item'>
                <Card.Body >
                  <Card.Title className='fw-bold  text-brown w-75 text-start'>Locate Us</Card.Title>
                  <Card.Text className='text-start w-75 fw-100'>Our Address</Card.Text>
                  <p className='text-start fw-bold text-dark'>VP Centre Akalad, Chavakkad, Kerala 680518</p>
                </Card.Body>
                <div className='contactus-wrap'></div>
                <button className="btn contactus-btn d-flex align-items-center justify-content-center rounded-5">
                  <IoLocation className='contactus-arrow' color='#431E06' size={15} />
                </button>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <div className="border rounded-5 bg-light h-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15685.129661033365!2d75.9890137!3d10.6351543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba797e7d6cf19d9%3A0xc83c491977121640!2sHubibee!5e0!3m2!1sen!2sin!4v1727187624454!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  className='rounded-5'
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Col>
            <Col md={6}>
              <div className="rounded-2 p-4 bg-transparent mb-4" style={{ position: 'sticky', top: '10vh' }}>
                <h4 className="text-start text-brown mb-3">Send us a Message</h4>
                {submitted && <Alert variant="success">Your message has been sent successfully!</Alert>}
                <Form onSubmit={handleSubmit} className="form-submit">
                  <Row>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          required
                          className='rounded-pill bg-gray border-0'
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Enter your name"
                          value={formData.name}
                          onChange={handleInputChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Label htmlFor="name">Name</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          required
                          className='rounded-pill bg-gray border-0'
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Enter your phone number"
                          value={formData.phone}
                          onChange={handleInputChange}
                          isInvalid={!!errors.phone}
                        />
                        <Form.Label htmlFor="phone">Phone</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Form.Floating className="mb-3">
                    <Form.Control
                      required
                      className='rounded-pill bg-gray border-0'
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleInputChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Floating>


                  <Form.Floating className="mb-3">
                    <Form.Control
                      as="textarea"
                      required
                      className='rounded-4 bg-gray border-0 text-brown'
                      id="message"
                      name="message"
                      placeholder="Enter your message"
                      value={formData.message}
                      onChange={handleInputChange}
                      style={{ height: '170px' }}
                      isInvalid={!!errors.message}
                    />
                    <Form.Label htmlFor="message">Compose</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Button type="submit" className="btn btn-dark bg-brown py-3 w-50 rounded-pill">
                    Send
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <RecentlyAdded />
        <OurProducts />
      </section>
    </div>
  );
};

export default Contactus;
