import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './BlogsHome.css';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

const BlogsHome = () => {
    const [largeBlogs, setLargeBlogs] = useState([]);
    const [smallBlogs, setSmallBlogs] = useState([]);

    const baseURL = 'https://hubibee.com/'

    useEffect(() => {
        getLargeBlogs();
        getSmallBlogs();
    }, [])

    const getLargeBlogs = async () => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/Blogs/largeblogs.php');
            if (response.data.length > 0) {
                setLargeBlogs(response.data);
            } else {
                setLargeBlogs([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getSmallBlogs = async () => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/Blogs/smallblogs.php');
            if (response.data.length > 0) {
                setSmallBlogs(response.data);
            } else {
                setSmallBlogs([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    };

    const trimContent = (content, maxLength) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = content;
        const textContent = tempElement.textContent || tempElement.innerText || '';
        return textContent.length > maxLength ? textContent.slice(0, maxLength - 3) + '...' : textContent;
    };

    const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html);
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-')
    };

    return (
        <div className="blogs-home">
            <Container>
                <section className="py-5">
                    <h4 className="title text-black py-4 text-capitalise">Our <span className="gradient-text">Blogs</span></h4>
                    <Row>
                        <Col xs={12} md={12} lg={7} className="mb-3">
                            {largeBlogs.map((blog, index) => (
                                <a href={`/blog/${generateSlug(blog.title)}`} key={index} >
                                    <Card className=" text-white rounded-3 blog-img-container blog-item-big border-0">
                                        <Card.Img src={baseURL + blog.image_url} alt="Blog image" className="card-img-big rounded-4" />
                                        <div className='blog-wrap-big'></div>
                                        <button className="btn blogs-btn d-flex align-items-center justify-content-center rounded-5">
                                            {formatDate(blog.doc)}
                                        </button>
                                        <Card.ImgOverlay className='d-flex flex-column justify-content-end'>
                                            <Card.Title className='text-white fs-4 w-75'>{blog.title}</Card.Title>
                                        </Card.ImgOverlay>
                                    </Card>
                                </a>
                            ))}
                        </Col>
                        <Col xs={12} md={12} lg={5}>
                            <Row className="mb-3 d-flex align-items-center justify-content-center">
                                {smallBlogs.map((blog, index) => (
                                    <Col key={index} md={6} sm={12} lg={12} className='mb-2'>
                                        <a href={`/blog/${generateSlug(blog.title)}`} key={index} >
                                            <Card className="text-white rounded-4 blog-img-container blog-item-small border-0">
                                                <Card.Img src={baseURL + blog.image_url} alt="Blog image" className="card-img-small shadow-lg rounded-4" style={{
                                                }} />
                                                <div className='blog-wrap-small'></div>
                                                <button className="btn blogs-btn d-flex align-items-center justify-content-center rounded-5">
                                                    {formatDate(blog.doc)}
                                                </button>
                                                <Card.ImgOverlay className='d-flex flex-column justify-content-end'>
                                                    <Card.Title className='text-white fs-5 w-75'>{blog.title}</Card.Title>
                                                </Card.ImgOverlay>
                                                <div className='blog-wrap-small'></div>
                                            </Card>
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default BlogsHome;
