import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BsTruck } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GoShieldCheck } from "react-icons/go";
import { IoArrowForwardOutline, IoStorefrontOutline } from "react-icons/io5";
import hive from '../../assets/images/hive.png';
import bee from '../../assets/images/bee.png';
import hivebag from '../../assets/images/hivebag.png';
import beenest from '../../assets/images/beenest.png';
import honeyjar from '../../assets/images/honeyjar.png';
import './Features.css';

const Features = () => {
    return (
        <section>
            <Container className="py-5">
                <Row className="text-center">
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-start justify-content-center mb-4'>
                        <Card className='border-0 p-2 rounded-4 h-100 w-100  features-item'>
                            <img
                                src={bee}
                                alt="bee"
                                className='featureImages'
                            />
                            <Card.Body >
                                <Card.Title className='fw-bold w-75 text-start'>High-Quality Production</Card.Title>
                                <Card.Text className='text-start w-75 fw-100'>Crafted with dedication and precision, ensuring premium results.</Card.Text>
                            </Card.Body>
                            <img
                                src={hive}
                                alt="Hive"
                                className='hiveImages'
                            />
                            <div className='features-wrap'></div>
                            <button className="btn features-btn d-flex align-items-center justify-content-center rounded-5">
                                <IoArrowForwardOutline className='feature-arrow' color='#431E06' size={15} />
                            </button>
                        </Card>
                    </Col>
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-start justify-content-center mb-4'>
                        <Card className='border-0 p-2 rounded-4  h-100  w-100 features-item'>
                            <img
                                src={hivebag}
                                alt="bee"
                                className='featureImages'
                            />
                            <Card.Body >
                                <Card.Title className='fw-bold w-75 text-start'>Diverse Honey
                                    Varieties</Card.Title>
                                <Card.Text className='text-start w-75  fw-100'>Explore a wide range of flavors and textures, naturally sourced.</Card.Text>
                            </Card.Body>
                            <img
                                src={hive}
                                alt="Hive"
                                className='hiveImages'
                            />
                            <div className='features-wrap'></div>
                            <button className="btn features-btn d-flex align-items-center justify-content-center rounded-5">
                                <IoArrowForwardOutline className='feature-arrow' color='#431E06' size={15} />
                            </button>
                        </Card>
                    </Col>
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-start justify-content-center mb-4'>
                        <Card className='border-0 p-2 rounded-4  h-100  w-100 features-item'>
                            <img
                                src={beenest}
                                alt="bee"
                                className='featureImages'
                            />
                            <Card.Body >
                                <Card.Title className='fw-bold w-75 text-start'>Natural and Pure</Card.Title>
                                <Card.Text className='text-start w-75  fw-100'>Our honey is untouched and unprocessed, as nature intended.</Card.Text>
                            </Card.Body>
                            <img
                                src={hive}
                                alt="Hive"
                                className='hiveImages'
                            />
                            <div className='features-wrap'></div>
                            <button className="btn features-btn d-flex align-items-center justify-content-center rounded-5">
                                <IoArrowForwardOutline className='feature-arrow' color='#431E06' size={15} />
                            </button>
                        </Card>
                    </Col>
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-start justify-content-center mb-4'>
                        <Card className='border-0 p-2 rounded-4  h-100  w-100 features-item'>
                            <img
                                src={honeyjar}
                                alt="bee"
                                className='featureImages'
                            />
                            <Card.Body >
                                <Card.Title className='fw-bold w-75 text-start'>Sustainability and Care</Card.Title>
                                <Card.Text className='text-start w-75 fw-100'>Committed to eco-friendly practices and ethical beekeeping methods.</Card.Text>
                            </Card.Body>
                            <img
                                src={hive}
                                alt="Hive"
                                className='hiveImages'
                            />
                            <div className='features-wrap'></div>
                            <button className="btn features-btn d-flex align-items-center justify-content-center rounded-5">
                                <IoArrowForwardOutline className='feature-arrow' color='#431E06' size={15} />
                            </button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Features;
