import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function BreadcrumHeader({ route: RouteComponent, title }) {
    return (
        <section style={{ marginTop: '-130px' }}>
            <div className="pb-4 mt-4">
                <Container fluid className='mobile-container'>
                    <Link className="breadcrumbheader-container" style={{ position: 'relative' }}>
                        <img
                            src='https://hubibee.com/media/sliders/1726494227.5649.jpg'
                            alt="Slide 1"
                            className='headerImage w-100 rounded-5 object-fit-cover'
                            height={'300'}
                        />
                        <div className="breadcrumb-overlay-text mx-5"
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                paddingInline: '10px',
                                zIndex: '23',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <p className='fs-2 mb-2 text-center gradient-text fw-bold'>{title}</p>
                            <Badge bg="light" text="dark" className='p-2 rounded-4 slider-badge mb-2 text-center'>
                                {/* Render the passed Breadcrumb component */}
                                {RouteComponent && <RouteComponent />}
                            </Badge>
                        </div>
                    </Link>
                </Container>
            </div>
        </section>
    )
}

export default BreadcrumHeader;
