import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';
import razorpaypng from '../../assets/images/razorpay.png'
import Whatsapp from '../Floaters/Whatsapp';
import Callus from '../Floaters/Callus';
import VideoPopup from '../Floaters/VideoPopup';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/images/brand.png';

const Footer = () => {

    return (
        <footer className="footer text-secondary pt-5" >
            <Container className='px-lg-5 px-md-5 px-sm-5'>
                <Row className="mb-4">
                    <Col xs={12} md={6} lg={4}>
                        <img
                            src={logoImage}
                            width="100"
                            height="100"
                            className="d-inline-block align-top"
                            alt="Karstar"
                        />
                        <p className='text-secondary mt-3' >
                            <span className='fw-bold text-brown'> Hubibee</span> honey producers work on the motto of “Let’s Be The Best Friend”. Hubibee honey producers are committed to producing the highest quality honey in collaboration with the National Horticulture Board.
                        </p>
                        <div className="social-icons mb-4 d-flex" >
                            <a className='text-secondary hive d-flex align-items-center justify-content-center' href="https://www.facebook.com/hubibee/" target='_blank'><FaFacebookF /></a>
                            <a className='text-secondary hive d-flex align-items-center justify-content-center' href="https://www.instagram.com/hubibee/" target='_blank'><FaTwitter /></a>
                            <a className='text-secondary hive d-flex align-items-center justify-content-center' href="https://www.instagram.com/hubibee/" target='_blank'><FaInstagram /></a>
                            <a className='text-secondary hive d-flex align-items-center justify-content-center' href="https://wa.me/918129616717?text=Hello,I%20have%20a%20question%20about%20https://hubibee.com/" target='_blank'><FaWhatsapp /></a>
                        </div>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3 fw-bold text-brown">Company</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/">Home</a></li>
                            <li><a className='text-secondary' href="/collections">Products</a></li>
                            <li><a className='text-secondary' href="/aboutus">About Us</a></li>
                            <li><a className='text-secondary' href="/store-locator">Store Locator</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={3}>
                        <h6 className="mb-3 fw-bold text-brown">Useful Links</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/exchangereturnpolicy">Return & Exchange Policy</a></li>
                            <li><a className='text-secondary' href="/cookiepolicy">Cookie Policy</a></li>
                            <li><a className='text-secondary' href="/accessibilitypolicy">Accessibility Policy</a></li>
                            <li><a className='text-secondary' href="/shippingpolicy">Shipping Policy</a></li>
                            <li><a className='text-secondary' href="/communityguidelines">Community Guidelines</a></li>
                            <li><a className='text-secondary' href="/copyrightnotice">Copyright Notice</a></li>
                            <li><a className='text-secondary' href="/disclaimer">Disclaimer</a></li>
                            {/* <li><a className='text-secondary' href="/contactinformation">Contact Information</a></li> */}
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3 fw-bold text-brown">Contact Us</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary fs-6' href="mailto:info@hubibee.com">info@hubibee.com</a></li>
                            <li><a className='text-secondary fs-6' href='tel:+919446195221'>+91 944 619 5221</a></li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={6}>
                        <p>© Hubibee | All Rights Reserved | © Copyright 2024</p>
                    </Col>
                    <Col md={6} className="text-md-end">
                        <p>
                            <a href="/termsandcondtions" className="text-decoration-none text-secondary">Terms & Conditions</a> |
                            <a href="/privacypolicy" className="text-decoration-none ms-2 text-secondary">Privacy Policy</a>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p className="text-muted text-center my-0" style={{ fontSize: '0.8rem' }}>Powered by w3builders Technology Solutions LLP</p>
                    </Col>
                </Row>
            </Container>
            <Whatsapp />
            <Callus />
            {/* <VideoPopup /> */}
        </footer>
    );
}

export default Footer;
