import React, { useState } from 'react';
import './Whatsapp.css';
import { FaChevronDown, FaWhatsapp } from 'react-icons/fa';
import { BsChevronBarDown } from 'react-icons/bs';

function Whatsapp() {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="whatsapp-container">
            <div onClick={togglePopup} className="float-whatsapp">
                <button type="button" className="whatsapp-button">
                    {isOpen ?
                        <FaChevronDown color="#fff" size={20} />
                        :
                        <FaWhatsapp color="#fff" size={20} />
                    }
                </button>
            </div>

            {isOpen && (
                <div className={`whatsapp-popup ${isOpen ? 'open' : ''}`}>
                    <div className="popup-message">
                        <b className='text-dark'>Hello There!</b>
                        <p>Thank you for your interest in The Hubibee products.
                            How may we help you today?</p>
                        <a href="https://wa.me/919446195221?text=Hello,I have a question about https://hubibee.com/" target="_blank" rel="noopener noreferrer">
                            <button type="button" className="chat-button w-100 align-center justify-content-center">
                                <FaWhatsapp color="#fff" size={20} className='me-2' />
                                Chat With Us
                            </button>
                        </a>
                        <div className="powered-by">
                            <small>Powered by w3builders.in</small>
                        </div>
                        <div className="arrow-box"></div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Whatsapp;


