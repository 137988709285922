import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import { Link } from 'react-router-dom';

const AccessibilityPolicy = () => {


  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
                   <div className='breadcrumb-item text-brown me-2'>Polcies</div>
          <div className='breadcrumb-item text-brown me-2'>1st September 2024</div>
          <div className='breadcrumb-item text-brown me-2'>Accessibility Policy</div>
        </div>
      </div>
    );
  };

  return (
    <div className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={'Accessibility Policy'} />
      <Container className="accessibility-policy">
        <Row>
          <Col>
            <h2>1. Accessibility Commitment</h2>
            <p>Hubibee ("we," "us," "our") is committed to ensuring that our website is accessible to individuals with disabilities. We strive to adhere to applicable accessibility standards and guidelines, including the Web Content Accessibility Guidelines (WCAG).</p>

            <h2>2. Accessible Design</h2>
            <p>We are continuously working to make our website more accessible by implementing accessible design and usability features.</p>

            <h2>3. Feedback and Assistance</h2>
            <h3>a. Feedback</h3>
            <p>If you encounter any accessibility issues while using our website, please provide feedback so that we can address the issue promptly.</p>

            <h3>b. Assistance</h3>
            <p>If you require assistance or have specific accessibility needs, please contact our customer support team for assistance.</p>

            <h2>4. Contact Us</h2>
            <p>If you have any questions or concerns about our Accessibility Policy or require assistance with accessibility features, please contact us from the contact us page.</p>
          </Col>
        </Row>
      </Container>
    </div>

  );
};

export default AccessibilityPolicy;
