import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
                   <div className='breadcrumb-item text-brown me-2'>Polcies</div>
          <div className='breadcrumb-item text-brown me-2'>1st September 2024</div>
          <div className='breadcrumb-item text-brown me-2'>Terms of Service Policy</div>
        </div>
      </div>
    );
  };

  return (
    <div className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={'Accessibility Policy'} />
      <Container className="terms-of-service">
        <Row>
          <Col>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using Hubibee ("we," "us," "our"), you agree to abide by these Terms of Service. If you do not agree with these terms, please do not use our website.</p>

            <h2>2. Use of the Website</h2>
            <h3>a. User Conduct</h3>
            <ul>
              <li>Use the site for any illegal or unauthorized purpose.</li>
              <li>Transmit any harmful code or interfere with the website's functionality.</li>
              <li>Violate any applicable laws or regulations.</li>
            </ul>

            <h3>b. No Cancellation Policy</h3>
            <p>All orders placed through our website are considered final and cannot be cancelled.</p>

            <h3>c. Return and Exchange Policy</h3>
            <p>We do not accept returns or exchanges through our website. Please visit the nearest store for any return or exchange inquiries.</p>

            <h2>3. Shipping and Delivery</h2>
            <h3>a. Shipping Methods</h3>
            <p>We offer various shipping methods, including those provided by Professional Couriers.</p>

            <h3>b. Estimated Delivery Times</h3>
            <p>Estimated delivery times are provided for reference and may vary based on location and other factors.</p>

            <h3>c. Delivery Issues</h3>
            <p>In the event of delivery issues, please contact our customer support team for assistance.</p>

            <h2>4. Payment Gateway</h2>
            <p>We do not store card or payment information. All transactions are securely processed through PhonePe Payment Gateway.</p>

            <h2>5. Disclaimer</h2>
            <p>All information provided on this website is for informational purposes only. We do not guarantee the accuracy, completeness, or suitability of the information for any particular purpose.</p>

            <h2>6. Intellectual Property</h2>
            <h3>a. Copyright</h3>
            <p>All content on our website, including text, images, and logos, is protected by copyright. Unauthorized use of our content is prohibited.</p>

            <h3>b. Seeking Permission</h3>
            <p>If you wish to use our content, please contact us to request permission.</p>

            <h2>7. Limitation of Liability</h2>
            <p>We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or any products purchased through it.</p>

            <h2>8. Governing Law and Dispute Resolution</h2>
            <p>These Terms of Service are governed by the laws of [Your Jurisdiction]. Any disputes arising from these terms will be resolved through [Specify Dispute Resolution Mechanism].</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns regarding our Terms of Service, please contact us from the contact us page.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsAndConditions;
