import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { IoCheckmarkCircle, IoStar, IoStarHalf, IoStarOutline, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './Testimonials.css';
import testimonialheroImage from '../../assets/images/testimonial.png'

const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
        stars.push(<IoStar key={`full-${i}`} color='gold' />);
    }

    if (halfStar) {
        stars.push(<IoStarHalf key="half" color='gold' />);
    }

    for (let i = 0; i < emptyStars; i++) {
        stars.push(<IoStarOutline key={`empty-${i}`} />);
    }

    return stars;
};

const Testimonials = () => {
    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const testimonials = [
        { id: 1, name: "John Doe", review: "Hubibee honey producers have a rich history rooted in a commitment to quality and collaboration. Established in partnership with the National Horticulture Board, Hubibee has always focused on producing the finest honey.", rating: 4.5 },
        { id: 2, name: "Jane Smith", review: "Absolutely loved it! Will buy again.", rating: 5 },
        { id: 3, name: "Michael Johnson", review: "Good value for money. Satisfied with the purchase.", rating: 4 },
        { id: 4, name: "Emily Davis", review: "Excellent service and quality.", rating: 3 },
        { id: 5, name: "Chris Brown", review: "Great support and product quality.", rating: 4.5 },
        { id: 6, name: "Lisa Ray", review: "Satisfied with the overall experience.", rating: 4 },
        { id: 7, name: "Michael Johnson", review: "Good value for money. Satisfied with the purchase.", rating: 4 },
        { id: 8, name: "Emily Davis", review: "Excellent service and quality.", rating: 3 },
    ];

    // Function to dynamically create hexagons in pairs of two rows
    const createHexagonGroups = () => {
        const groups = [];

        for (let i = 0; i < testimonials.length; i += 4) {
            const group = (
                <div className="hexagon-testimonials-wrapper" key={i}>
                    {/* First Row */}
                    <div className="hexagon-testimonials-list clearfix">
                        {testimonials.slice(i, i + 2).map((testimonial) => (
                            <div className="hexagon-testimonials" key={testimonial.id}>
                                <div className='review-container'>
                                    <div className='text-center mb-0 mt-0'>
                                        {renderStars(testimonial.rating)}
                                    </div>
                                    <div className='text-center text-white reviewer-name'>
                                        {testimonial.name} <IoCheckmarkCircle color="green" />
                                    </div>
                                    <div className='text-center text-white review-text d-none d-lg-block d-md-block'>
                                        {testimonial.review}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* Second Row */}
                    <div className="hexagon-testimonials-list clearfix">
                        {testimonials.slice(i + 2, i + 4).map((testimonial) => (
                            <div className="hexagon-testimonials" key={testimonial.id}>
                                <div className='review-container'>
                                    <div className='text-center'>
                                        {renderStars(testimonial.rating)}
                                    </div>
                                    <div className='text-center text-white reviewer-name'>
                                        {testimonial.name} <IoCheckmarkCircle color="green" />
                                    </div>
                                    <div className='text-center text-white review-text d-none d-lg-block d-md-block'>
                                        {testimonial.review}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
            groups.push(group);
        }

        return groups;
    };

    // Settings for the slick slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Shows one 4-cube hive at a time
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <div className='testimonial-container pb-5'>
            <Container>
                <section className='py-5'>
                    <div className="row pb-5">
                        <div className="col-sm-12 col-lg-6 col-md-12">
                            <h4 className='title text-start text-black py-2 text-capitalize'>
                                <span className='gradient-text'>Testimonials</span>
                            </h4>
                            <p className="stext-113 text-brown cl6 p-b-26 fw-bold fs-1" style={{ fontSize: '24px' }}>
                                What they’re talking about our company?
                            </p>
                            <p className="stext-113 text-brown cl6 p-b-26 fw-bold fs-5" style={{ fontSize: '24px' }}>
                            Hubibee honey is the natural wild honey extracted from the bee hives placed near the Sidr Trees. It is extracted by the professional honey hunters and processed well to provide you the purest wild honey.
                            </p>
                            <div className='mb-3'>
                                <button className="btn me-2 swiper-arrows" onClick={previous}>
                                    <IoChevronBack size={25} />
                                </button>
                                <button className="btn swiper-arrows" onClick={next}>
                                    <IoChevronForward size={25} />
                                </button>
                            </div>
                            <button className="btn btn-yellow me-2 fw-light rounded-5 px-3">
                                More About Us
                            </button>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-md-12">
                            <Slider ref={sliderRef} {...settings}>
                                {createHexagonGroups()}
                            </Slider>
                            {/* <img src={testimonialheroImage} alt="testimonial-hero" className='testimonial-hero d-none d-lg-block d-md-block'/> */}
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    );
};

export default Testimonials;
