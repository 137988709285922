import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Alert, Button, Form, ProgressBar, Row, Col, Card } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function Password() {
    const { user_id, token } = useParams(); // Extract the user_id and token from the URL
    const [verificationStatus, setVerificationStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthText, setPasswordStrengthText] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // For redirecting to another screen

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const response = await axios.post('https://hubibee.com/api/client_api/authentication/reset/verify.php', {
                    user_id,
                    token
                });
                if (response.data.success) {
                    setVerificationStatus(response.data.message);
                } else {
                    setVerificationStatus(response.data.message);
                }
            } catch (err) {
                console.error('Verification error:', err);
                setVerificationStatus('Verification failed. Please try again or contact support.');
            } finally {
                setLoading(false);
            }
        };

        if (user_id) {
            verifyUser();
        }
    }, [user_id, token]);

    const calculatePasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 1;
        if (/[a-z]/.test(password)) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/[0-9]/.test(password)) strength += 1;
        if (/[^a-zA-Z0-9]/.test(password)) strength += 1;

        setPasswordStrength(strength);

        if (strength === 5) {
            setPasswordStrengthText('Strong');
        } else if (strength >= 3) {
            setPasswordStrengthText('Medium');
        } else {
            setPasswordStrengthText('Weak');
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setShowPasswordStrength(newPassword.length > 0);
        calculatePasswordStrength(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);

        if (password && newConfirmPassword !== password) {
            setPasswordMatchError('Passwords do not match.');
        } else {
            setPasswordMatchError('');
        }
    };

    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordMatchError && passwordStrength >= 3) {
            try {
                const response = await axios.post('https://hubibee.com/api/client_api/authentication/reset/update_password.php', {
                    user_id,
                    token,
                    newPassword: password
                });

                console.log(response);

                if (response.data.success) {
                    setSuccessMessage('Password updated successfully.');
                    setTimeout(() => {
                        navigate('/auth/login');
                    }, 3000);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                console.error('Password update error:', err);
                setError('Password update failed. Please try again or contact support.');
            }
        }
    };

    const handlePasswordReset = () => {
        navigate('/auth/reset'); // Redirects to the login screen
    };


    return (
        <Container className="sectionView d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Alert variant={verificationStatus?.includes('successfully') || verificationStatus.includes('already verified') ? 'success' : 'danger'}>
                        {verificationStatus}
                    </Alert>

                    {verificationStatus?.includes('Verification link expired') || verificationStatus?.includes('invalid or has expired') ?
                        <Button variant="outline-light" className="rounded-5 py-2 w-50 mt-3" onClick={handlePasswordReset}>
                            Resend Verification Link Again
                        </Button>
                        :
                        <Row className="w-100">
                            <Col md={8} className="mx-auto">
                                <h2 className="text-center mb-4 text-white">Reset Password</h2>
                                <Card className='bg-transparent'>
                                    <Card.Body className='bg-white rounded-end rounded-start-sm h-100 p-lg-5 py-5 '>

                                        {successMessage && <Alert variant="success">{successMessage}</Alert>}

                                        {error && <Alert variant="danger">{error}</Alert>}

                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Floating className="mb-3 position-relative">
                                                <Form.Control
                                                    required
                                                    className='rounded-5'
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    minLength={8}
                                                />
                                                <Form.Label htmlFor="password">Password</Form.Label>
                                                <Form.Control.Feedback type="invalid">
                                                    Password must be at least 8 characters long and include letters, numbers, and special characters.
                                                </Form.Control.Feedback>
                                                <span onClick={toggleShowPassword} className="position-absolute end-0 top-50 translate-middle-y me-5">
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </Form.Floating>

                                            {showPasswordStrength && (
                                                <div className="my-2">
                                                    <ProgressBar now={(passwordStrength / 5) * 100} variant={passwordStrengthText === 'Strong' ? 'success' : passwordStrengthText === 'Medium' ? 'warning' : 'danger'} />
                                                    <small className="text-muted">{passwordStrengthText}</small>
                                                </div>
                                            )}

                                            <Form.Floating className="mb-3 position-relative">
                                                <Form.Control
                                                    required
                                                    className='rounded-5'
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    id="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPasswordChange}
                                                />
                                                <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
                                                <Form.Control.Feedback type="invalid">
                                                    Please confirm your password.
                                                </Form.Control.Feedback>
                                                <span onClick={toggleShowConfirmPassword} className="position-absolute end-0 top-50 translate-middle-y me-5">
                                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </Form.Floating>

                                            {passwordMatchError && (
                                                <div className="text-danger my-2">
                                                    {passwordMatchError}
                                                </div>
                                            )}

                                            <Button variant="dark" type="submit" className="rounded-5 text-center py-3 w-100">
                                                Reset Password
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            )}
        </Container>
    );
}

export default Password;
