import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import { AiOutlineHome } from 'react-icons/ai';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import SecureLS from "secure-ls";
import BreadcrumHeader from '../../Utils/BreadcrumHeader';

function Account() {
  const ls = new SecureLS({ encodingType: 'aes' });

  const [showSidebar, setShowSidebar] = useState(false);
  const [formData, setFormData] = useState({
    newphonenumber: '',
    newaddress1: '',
    newaddress2: '',
    newcity: '',
    newpincode: '',
    newstate: '',
    newlandmark: '',
    newaddresstype: '',
  });
  const [validated, setValidated] = useState(false);
  const [myDetails, setMyDetails] = useState({});

  useEffect(() => {
    getMyDetails();
  }, [])

  const getMyDetails = async () => {
    try {
      const email = ls.get('userEmail');
      if (email) {
        const response = await axios.get(`https://hubibee.com/api/client_api/authentication/account/index.php?email=${encodeURIComponent(email)}`);
        setMyDetails(response.data);
        console.log(response.data)
      } else {
        console.error("User email is not found in local storage.");
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (showSidebar) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showSidebar]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const response = await axios.post('/your-api-endpoint', formData);
        console.log('Response:', response.data);
        setShowSidebar(false); // Close the sidebar on success
      } catch (error) {
        console.error('Error submitting the form:', error);
        // Handle error (e.g., show an error message)
      }
    }
    setValidated(true);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleLogout = async () => {
    try {
      // Send the POST request to the logout endpoint
      const response = await axios.post('https://hubibee.com/api/client_api/authentication/logout/index.php');
      console.log(response.data);
      // Check if the response indicates success
      if (response.data.success) {
        // Remove user data from SecureLS
        ls.remove('userEmail');
        ls.remove('userName');

        // Redirect to home page
        window.location.href = '/';
      } else {
        // Handle the case where the server response is not successful
        alert('Error logging out, please try again');
      }
    } catch (error) {
      // Handle any errors during the request
      console.error('Error logging out:', error);
      alert('Error logging out: Please try again.');
    }
  };


  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
          <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
          <div className='breadcrumb-item text-brown me-2'>Account</div>
          <div className='breadcrumb-item text-brown me-2'>{myDetails.Firstname} {myDetails.Lastname}</div>
        </div>
      </div>
    );
  };

  return (
    <section className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={myDetails.Firstname + ' ' + myDetails.Lastname} />
      <Container >
        <Row >
          <Col md={3} className="mb-3">
            <div style={{ position: 'sticky', top: '10vh' }}>
              <Row >
                <Col md={12} className="mb-3">
                  <Card className="h-100 w-100 primarybg shadow">
                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                      <label htmlFor="profilepic" className="viewimage_btn">
                        <div className="image-wrapper">
                          <img
                            src={myDetails.profileImage}
                            alt=""
                            style={{ borderRadius: '40px', border: '1px solid gray' }}
                            className="preview-image"
                            width="80px"
                            height="80px"
                          />
                          <div className="overlay">
                            <span className="text"><i className="fas fa-camera"></i></span>
                          </div>
                        </div>
                      </label>
                      <div className="profileImageID">
                      </div>
                      <h5 className="fw-bold text-black mt-3">{myDetails.Firstname} {myDetails.Lastname} </h5>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12} className="mb-3">
                  <Card className="h-100 w-100 shadow">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-start blue f16 fw-bold">My Personal Details</h5>
                      </div>
                      <Row>
                        <Col sm={12} className="mb-2">
                          <div className="d-flex justify-content-start align-items-start">
                            <i className="fa-solid fa-user blue f22"></i>
                            <div className="mx-2">
                              <h5 className="text-start blue f16 fw-bold mb-0">Full Name</h5>
                              <p className="mb-0 text-start">{myDetails.Firstname} {myDetails.Lastname}</p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} className="mb-2">
                          <div className="d-flex justify-content-start align-items-start">
                            <i className="fas fa-at blue f22"></i>
                            <div className="mx-2">
                              <h5 className="text-start blue f16 fw-bold mb-0">Email Id</h5>
                              <p className="mb-0 text-start">{myDetails.Email_Id}</p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} className="mb-2">
                          <div className="d-flex justify-content-start align-items-start">
                            <i className="fas fa-at blue f22"></i>
                            <div className="mx-2">
                              <h5 className="text-start blue f16 fw-bold mb-0">Mobile Number</h5>
                              <p className="mb-0 text-start">{myDetails.Mobile_No}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12} className="mb-3">
                  <Card className="h-100 w-100 shadow">
                    <Card.Body className="myprofileoptions">
                      <div className="dropdown-options text-start">
                        <a href="myorders">
                          My Orders
                        </a>
                      </div>
                      {/* <div className="dropdown-options  text-start">
                    <a href="lifycoins">
                      <i className="fas fa-coins blue"></i>
                      My Lify Coins
                    </a>
                  </div> */}
                      <div className="dropdown-options text-start">
                        <a data-bs-toggle="modal" style={{ cursor: 'pointer' }} onClick={handleShow} data-bs-target="#confirmlogoutmodal">
                          <i className="fa fa-sign-out blue" aria-hidden="true"></i>
                          Logout
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={9} className="mb-3">
            <Row>
              <Col md={12} className="mb-3">
                <Card className="h-100 w-100 shadow">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="text-start blue f16 fw-bold">My Addresses</h5>
                      <Button className="btn btn-dark mt-auto align-self-start m-1 addnew_delivery_address" onClick={toggleSidebar} >+ Update Address</Button>
                    </div>
                    <Row id="allAddress">
                      <Col md={12} className="mb-3">
                        <Card className="h-100 w-100 shadow d-flex">
                          <Card.Body className="d-flex flex-column">
                            <p className="mb-0 text-start f14 pb-3">{myDetails.address1} {myDetails.address1} {myDetails.city} {myDetails.Zip} {myDetails.states}</p>
                            <p className="mb-0 text-start f14 pb-0">{myDetails.Mobile_No}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <aside className={`wrap-sidebar js-sideorderbar ${showSidebar ? 'show-sidebar' : ''}`}>
          <div className="sidebar p-t-22 p-b-25">
            <div className="d-flex justify-content-between align-items-center bg-white" style={{ position: 'sticky', top: 0, zIndex: 1, background: "#fff" }}>
              <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                + Add New Address
              </div>
              <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={toggleSidebar}>
                <FaTimes size={'22px'} color='#000' className="icon" />
              </div>
            </div>
            <div className="sidebar-menu-content">
              <Form id="addressform" className='w-100' noValidate validated={validated} onSubmit={handleSubmit}>
                <h5 className="card-title text-start blue f16 fw-bold mb-2">Address Details</h5>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    pattern="[0-9]{10}"
                    maxLength="10"
                    className="rounded-5"
                    type="tel"
                    id="newphonenumber"
                    placeholder="Enter Phone Number"
                    name="newphonenumber"
                    value={formData.newphonenumber}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newphonenumber">Enter Phone Number</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid 10-digit phone number.
                  </Form.Control.Feedback>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    className="rounded-5"
                    type="text"
                    id="newaddress1"
                    placeholder="Enter Address 1"
                    name="newaddress1"
                    value={formData.newaddress1}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newaddress1">Enter Address 1</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide Address 1.
                  </Form.Control.Feedback>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    className="rounded-5"
                    type="text"
                    id="newaddress2"
                    placeholder="Enter Address 2"
                    name="newaddress2"
                    value={formData.newaddress2}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newaddress2">Enter Address 2</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide Address 2.
                  </Form.Control.Feedback>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    className="rounded-5"
                    type="text"
                    id="newcity"
                    placeholder="Enter City"
                    name="newcity"
                    value={formData.newcity}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newcity">Enter City</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid city.
                  </Form.Control.Feedback>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    pattern="[0-9]{6}"
                    maxLength="6"
                    className="rounded-5"
                    type="text"
                    id="newpincode"
                    placeholder="Enter Pincode"
                    name="newpincode"
                    value={formData.newpincode}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newpincode">Enter Pincode</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid 6-digit pincode.
                  </Form.Control.Feedback>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    className="rounded-5"
                    type="text"
                    id="newstate"
                    placeholder="Enter State"
                    name="newstate"
                    value={formData.newstate}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newstate">Enter State</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid state.
                  </Form.Control.Feedback>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    required
                    className="rounded-5"
                    type="text"
                    id="newlandmark"
                    placeholder="Enter Landmark"
                    name="newlandmark"
                    value={formData.newlandmark}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="newlandmark">Enter Landmark</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide a landmark.
                  </Form.Control.Feedback>
                </Form.Floating>

                <h5 className="card-title text-start blue f16 fw-bold mb-0">Address Type</h5>
                <Card.Body className="d-flex flex-row">
                  <Form.Check
                    inline
                    type="radio"
                    label="Home"
                    name="newaddresstype"
                    id="hometype"
                    value="Home"
                    checked={formData.newaddresstype === 'Home'}
                    onChange={handleChange}
                    required
                    className="btn-check"
                  />
                  <label className="btn primarizy-outline m-1 w-100" htmlFor="hometype">
                    Home
                  </label>

                  <Form.Check
                    inline
                    type="radio"
                    label="Office"
                    name="newaddresstype"
                    id="officetype"
                    value="Office"
                    checked={formData.newaddresstype === 'Office'}
                    onChange={handleChange}
                    required
                    className="btn-check"
                  />
                  <label className="btn primarizy-outline m-1 w-100" htmlFor="officetype">
                    Office
                  </label>

                  <Form.Check
                    inline
                    type="radio"
                    label="Other"
                    name="newaddresstype"
                    id="othertype"
                    value="Other"
                    checked={formData.newaddresstype === 'Other'}
                    onChange={handleChange}
                    required
                    className="btn-check"
                  />
                  <label className="btn primarizy-outline m-1 w-100" htmlFor="othertype">
                    Other
                  </label>
                </Card.Body>

                <Button type="submit" name="registerbtn" className="btn primarizy w-100 mb-2">
                  Save Address
                </Button>
              </Form>
            </div>
          </div>
        </aside>

        {/* Logout Confirmation Modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className='border-0'>
            <Modal.Title>Confirm Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to log out?</Modal.Body>
          <Modal.Footer className='border-0'>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="dark" onClick={handleLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section >
  );
}

export default Account;
