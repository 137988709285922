import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { IoCheckmarkCircle, IoStar, IoStarHalf, IoStarOutline, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './Aboutus.css';
// import testimonialheroImage from '../../assets/images/abi.png'
import { Link } from 'react-router-dom';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import OurProducts from '../../components/Products/OurProducts';

const renderStars = (rating) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  for (let i = 0; i < fullStars; i++) {
    stars.push(<IoStar key={`full-${i}`} color='gold' />);
  }

  if (halfStar) {
    stars.push(<IoStarHalf key="half" color='gold' />);
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(<IoStarOutline key={`empty-${i}`} />);
  }

  return stars;
};

const Aboutus = () => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const mission = [
    { id: 1, image: "", },
    { id: 2, image: "", },
    { id: 3, image: "", },
    { id: 4, image: "", },
  ];

  const vision = [
    { id: 1, image: "", },
    { id: 2, image: "", },
    { id: 3, image: "", },
    { id: 4, image: "", },
  ];

  // Function to dynamically create hexagons in pairs of two rows
  const createHexagonGroups = () => {
    const groups = [];

    for (let i = 0; i < mission.length; i += 4) {
      const group = (
        <div className="hexagon-wrapper" key={i}>
          {/* First Row */}
          <div className="hexagon-list clearfix">
            {mission.slice(i, i + 2).map((mission) => (
              <div className="hexagon" key={mission.id}>

              </div>
            ))}
          </div>
          {/* Second Row */}
          <div className="hexagon-list clearfix">
            {mission.slice(i + 2, i + 4).map((mission) => (
              <div className="hexagon" key={mission.id}>

              </div>
            ))}
          </div>
        </div>
      );
      groups.push(group);
    }

    return groups;
  };

  // Settings for the slick slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Shows one 4-cube hive at a time
    slidesToScroll: 1,
    arrows: false,
  };

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
          <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
          <div className='breadcrumb-item text-brown me-2'>About Us</div>
        </div>
      </div>
    );
  };

  return (
    <div className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={'About Hubibee'} />
      <Container className='py-5'>
        <div className="row pb-5">
          <div className="col-sm-12 col-lg-6 col-md-12">
            {createHexagonGroups()}
          </div>
          <div className="col-sm-12 col-lg-6 col-md-12">
            <h4 className='title text-start text-black py-2 text-capitalize'>
              <span className='gradient-text'>Mission</span>
            </h4>
            <p className="stext-113 text-brown cl6 p-b-26 fw-bold fs-5" style={{ fontSize: '24px' }}>
              At Hubibee, our mission is to produce and deliver the highest quality honey, rich in goodness and nutritional value. We strive to be a trusted friend to both nature and our customers by ensuring that our honey is natural, pure, and beneficial. By guiding hardworking bees to collect nectar from distinct plants, we are able to offer a variety of honey types, each tailored to meet the unique tastes and health needs of our consumers. Our mission is to bring the purest and most natural honey from the hive to your table, ensuring that every jar is a testament to quality and care.
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-sm-12 col-lg-6 col-md-12">
            <h4 className='title text-start text-black py-2 text-capitalize'>
              <span className='gradient-text'>Vision</span>
            </h4>
            <p className="stext-113 text-brown cl6 p-b-26 fw-bold fs-5" style={{ fontSize: '24px' }}>
              Our vision at Hubibee is to be recognized globally as a leader in natural honey production, known for our commitment to quality, sustainability, and innovation. We aim to create a world where natural and pure honey is a staple in every household, providing not just a delicious food product, but also a source of health and wellness. We envision expanding our product offerings and reaching new markets while maintaining our core values of purity, sustainability, and customer satisfaction. By fostering a strong relationship with nature and our customers, we aspire to continue growing as a brand synonymous with trust, quality, and excellence in honey production.
            </p>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-12">
            {createHexagonGroups()}
          </div>
        </div>
        <RecentlyAdded />
        <OurProducts />
      </Container>
    </div>
  );
};

export default Aboutus;
