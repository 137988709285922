import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Form, FormControl, InputGroup, Button, Modal } from 'react-bootstrap';
import './Header.css';
import { IoCartOutline, IoPersonCircleOutline, IoMenuSharp, IoPersonOutline, IoSearchOutline } from "react-icons/io5";
import logoImage from '../../assets/images/brand.png';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SecureLS from "secure-ls";
import { FaCalendar, FaFacebook, FaFacebookF, FaInstagram, FaTimes, FaWhatsapp } from 'react-icons/fa';

function Header() {
    const { cartCount } = useContext(CartContext);
    const { wishlistCount } = useContext(WishlistContext); // Use wishlist context

    const [searchInput, setSearchInput] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [headerBackground, setHeaderBackground] = useState(false); // Track header background color

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput !== '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // Close the sidebar first
        handleSidebarClose();

        // After closing the sidebar, show the logout confirmation modal
        setTimeout(() => {
            setShow(true);
        }, 300); // Adjust the delay if necessary
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;

            // Change header background when scrolled down
            if (currentScrollTop > 50) {
                setHeaderBackground(true); // Scrolled down, change background to white
            } else {
                setHeaderBackground(false); // At the top, remove white background
            }

            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    const [categories, setCategories] = useState([]);
    const baseUrl = 'https://hubibee.com/';

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    const ls = new SecureLS({ encodingType: 'aes' });

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        const email = ls.get('userEmail');
        const name = ls.get('userName');

        if (email && name) {
            setIsLoggedIn(true);
            setUserName(name);
        }
    }, []);

    const SignInLink = () => {
        const location = useLocation();

        const handleSignInClick = () => {
            // Store the current path in sessionStorage
            sessionStorage.setItem('redirectPath', location.pathname);
        };

        return (
            <Link
                to='/auth/login'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
                onClick={handleSignInClick} // Call this function on click
            >
                <div className="icon-text-container">
                    <IoPersonCircleOutline size={'22px'} color='#431E06' className="icon" />
                </div>
            </Link>
        );
    };


    const UserDropdown = () => {
        return (
            <Link
                to='/account'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
            >
                <div className="icon-text-container">
                    <IoPersonCircleOutline size={'22px'} color='#431E06' className="icon" />
                </div>
            </Link>
        );
    };

    const handleMenuClick = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleLogout = async () => {
        try {
            const response = await axios.post('https://hubibee.com/api/client_api/authentication/logout/index.php');

            if (response.data.success) {
                ls.remove('userEmail');
                ls.remove('userName');
                setIsLoggedIn(false);
                setUserName('');
                window.location.href = 'https://hubibee.com';
            } else {
                alert('Error logging out, please try again');
            }
        } catch (error) {
            console.error('Error logging out:', error);
            alert('Error logging out: Please try again.');
        }
    };

    return (
        <div>
            <header className={`custom-header ${headerBackground ? 'header-scrolled' : ''}`}>
                <div className='top-header px-lg-5 px-md-5 px-sm-5 pt-lg-4 pt-sm-4 pt-md-4'>
                    <Container fluid className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center" style={{ flexBasis: '60%' }}>
                            <div className="logo me-2">
                                <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                    <Link to="/">
                                        <img
                                            src={logoImage}
                                            width="auto"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="Karstar"
                                        />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                            <div className='d-none justify-content-center align-items-center d-lg-flex'>
                                <Navbar id="basic-navbar-nav">
                                    <Nav className="me-auto text-white">
                                        <Nav.Link as={Link} to="" className="text-brown fw-bold me-2 bottomNavbarCategories">Home</Nav.Link>
                                        <Nav.Link as={Link} to="collection/All-Collections" className="text-brown fw-bold me-2 bottomNavbarCategories">Shop</Nav.Link>
                                        <Nav.Link as={Link} to="contactus" className="text-brown fw-bold me-2 bottomNavbarCategories">Contact Us</Nav.Link>
                                        <Nav.Link as={Link} to="aboutus" className="text-brown fw-bold me-2 bottomNavbarCategories">About Us</Nav.Link>
                                        <Nav.Link as={Link} to="blogs" className="text-brown fw-bold me-2 bottomNavbarCategories">Blogs</Nav.Link>
                                    </Nav>
                                </Navbar>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ flexBasis: '50%' }}>
                            <div className="search-bar d-none d-lg-block">
                                <Form>
                                    <InputGroup>
                                        <div style={{ position: 'relative', width: '36rem' }}>
                                            <FormControl
                                                style={{
                                                    border: 'none',
                                                    padding: '8px 10px 8px 40px',
                                                    width: '100%',
                                                    borderRadius: '25px',
                                                    background: '#F0F0F0'
                                                }}
                                                aria-label="Search Products"
                                                placeholder="Search Products"
                                                value={searchInput}
                                                onChange={handleInputChange}
                                                className='me-5 text-brown'
                                            />
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: '8px',
                                                    left: '13px',
                                                }}
                                            >
                                                <IoSearchOutline size={'18px'} className='text-brown fw-bold' />
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Form>
                            </div>
                            <div className='d-flex'>

                                <div className='text-decoration-none d-flex d-lg-none align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoSearchOutline size={'22px'} color='#431E06' className="icon" />
                                    </div>
                                </div>

                                <Link to='Cart' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoCartOutline size={'22px'} color='#431E06' className="icon" />
                                        <span className='icon-badge cart-badge fw-bold text-brown'>{cartCount} </span>
                                    </div>
                                </Link>
                                {isLoggedIn ? <UserDropdown /> : <SignInLink />}
                                <div className='text-decoration-none d-flex d-lg-none align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoMenuSharp size={'22px'} color='#431E06' className="icon"
                                            onClick={() => handleMenuClick()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </header>

            {/* Menu Sidebar */}
            <aside className={`wrap-sidebar js-sideorderbar ${isSidebarOpen ? 'show-sidebar' : 'd-none'}`}>
                <div className="sidebar p-t-22 p-b-25">
                    <div className="d-flex justify-content-between align-items-center " style={{ position: 'sticky', top: 0, zIndex: 1, background: "#fff" }}>
                        <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                            <div className="logo" style={{ flexBasis: '10%' }}>
                                <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                    <Link to="/">
                                        <img
                                            src={logoImage}
                                            width="auto"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="Karstar"
                                        />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </div>
                        <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={handleSidebarClose}>
                            <FaTimes size={'22px'} color='#000' className="icon" />
                        </div>
                    </div>
                    <div className="dropdown px-5">
                        <div className="dropdown-options py-2">
                            <Link to="/">
                                Home
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="collection/All-Products">
                                All Pefumes
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="aboutus">
                                About Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="contactus">
                                Contact Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="store-locator">
                                Store Locator
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="track-order">
                                Track Order
                            </Link>
                        </div>
                    </div>
                </div>
            </aside>

            {/* Logout Confirmation Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Header;
