import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { IoArrowForward, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import axios from 'axios';
import { Link } from 'react-router-dom';


function OurFragrances() {
    const [loading, setLoading] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0);
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        slidesToShow: 4.4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        responsive: [
            {
                breakpoint: 1400, // xl (extra large)
                settings: {
                    slidesToShow: 4.4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200, // lg (large)
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992, // md (medium)
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768, // sm (small)
                settings: {
                    slidesToShow: 2.15,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576, // xs (extra small)
                settings: {
                    slidesToShow: 1.35,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 486, // xxs (extra extra small)
                settings: {
                    slidesToShow: 1.24,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [fragrances, setFragrances] = useState([])

    const baseURL = 'https://hubibee.com/'

    useEffect(() => {
        getFragrances();
    }, [])

    const getFragrances = async () => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/Fragrances/index.php');
            setFragrances(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const FragranceCard = ({ fragrance }) => {
        // Parse the JSON string into an array
        const tagsArray = JSON.parse(fragrance.tags);
        // Join the array elements with commas
        const tagsString = tagsArray.join(', ');
        return (
            <Link to={`/collection/${fragrance.name}`}>
                <Card className='me-1 bg-transparent card-hover' style={{ borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', position: 'relative' }}>
                    <div className="card-img-container" style={{ borderRadius: 0, borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}>
                        <Card.Img src={baseURL + fragrance.image_path} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} />
                    </div>
                    <div className="w-100 px-2 py-2 d-flex justify-content-between align-items-center" style={{ background: 'white', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem' }}>
                        <div>
                            <Card.Title className='text-start text-black mb-1 fw-bold ShopByGenderTag' style={{ fontSize: '16px' }}>
                                {fragrance.name}
                            </Card.Title><br />
                            <Card.Title className='text-start mb-0 fw-light' style={{ fontSize: '12px' }}>
                                {tagsString}
                            </Card.Title>
                        </div>
                        <Button variant="transparent" className='rounded-5 py-2' style={{ display: 'flex', alignItems: 'center' }}>
                            <IoArrowForward className='rotate-down' />
                        </Button>
                    </div>
                </Card>
            </Link>
        );
    };

    return (
        <div>
            <Container fluid className='px-lg-0 px-md-0 px-sm-0 px-0'>
                <section className='pt-3'>
                    <Row className="g-0">
                        <Col>
                        <div className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-sm-5 px-3'>
                                <div>
                                    <h4 className='title text-white py-2 text-uppercase'>Our <span className='gradient-text'>Fragrances</span></h4>
                                </div>
                                <div>
                                    <button
                                        className="btn me-2  swiper-arrows"
                                        style={{
                                            border: `2px solid ${activeSlide === 0 ? '#333' : '#fff'}`,
                                        }}
                                        onClick={previous}
                                    >
                                        <IoChevronBack color={activeSlide === 0 ? '#333' : '#fff'} size={25} />
                                    </button>
                                    <button
                                        style={{
                                            border: '2px solid #fff',
                                        }}
                                        className="btn swiper-arrows"
                                        onClick={next}
                                    >
                                        <IoChevronForward color='#fff' size={25} />
                                    </button>
                                </div>
                            </div>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div style={{ position: 'relative' }}>
                                    <Slider
                                        ref={slider => {
                                            sliderRef = slider;
                                        }}
                                        {...productsSettings}
                                        className="top-categories pt-2"
                                    >
                                        {fragrances.map((fragrance, index) => (
                                            <FragranceCard key={index} fragrance={fragrance} />
                                        ))}
                                    </Slider>
                                </div>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default OurFragrances;
