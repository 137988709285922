import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import SecureLS from "secure-ls";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const [cartCount, setCartCount] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);

    const fetchCartCount = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');

        if (username == "") {
            if (sessionId !== null) {
                getCartCount(sessionId, 'na');
            }
        } else {
            getCartCount('na', username);
        }
    };

    const fetchCartItems = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        if (username == "") {
            if (sessionId !== null) {
                getCartItems(sessionId, 'na');
            }
        } else {
            getCartItems('na', username);
        }
    };

    const getCartCount = (sessionId, username) => {
        axios.get(`https://hubibee.com/api/client_api/cart/CartCount/?ipadd=${sessionId}&email=${username}`)
            .then(response => {
                if (response.data.cartcount == null) {
                    setCartCount(0);
                    setCartTotal(0);
                } else {
                    const cartCount = parseInt(response.data.cartcount, 10);
                    const cartTotal = parseInt(response.data.total, 10);
                    setCartCount(cartCount);
                    setCartTotal(cartTotal);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const getCartItems = (sessionId, username) => {
        axios.get(`https://hubibee.com/api/client_api/cart/?ipadd=${sessionId}&email=${username}`)
            .then(response => {
                setCartItems(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const addToCart = async (sessionId, username, item) => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/cart/addtoCart/index.php', {
                params: {
                    prodcode: item.selectedProdCode,
                    ipadd: sessionId,
                    qty: item.quantity,
                    email: username
                }
            });

            if (response.data.message === 'added to cart!' || response.data.message === 'updated cart!') {
                fetchCartItems(); // Refresh cart after adding item
                setCartCount(prevCount => prevCount + parseInt(item.quantity, 10)); // Update cart count
                setCartTotal(prevTotal => parseInt(prevTotal, 10) + parseInt(item.selectedProdCode.discountedPrice, 10)); // Update cart total
                return true; // Indicate success
            } else {
                console.error(response.data);
                return false; // Indicate failure
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            return false; // Indicate failure
        }
    };

    const removeFromCart = async (sessionId, username, item) => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/cart/removefromCart/index.php', {
                params: {
                    prodcode: item.selectedProdCode,
                    ipadd: sessionId,
                    qty: item.quantity,
                    email: username
                }
            });
            if (response.data.message === 'Removed from cart' || response.data.message === 'Quantity decreased') {
                fetchCartItems(); // Refresh cart after removing item
                setCartCount(prevCount => prevCount - parseInt(item.quantity, 10)); // Update cart count
                setCartTotal(prevTotal => parseInt(prevTotal, 10) - parseInt(item.selectedProdCode.discountedPrice, 10)); // Update cart total
                return true; // Indicate success
            } else {
                return false; // Indicate failure
            }
        } catch (error) {
            console.error('Error removing from cart:', error);
            return false; // Indicate failure
        }
    };

    useEffect(() => {
        fetchCartCount();
        fetchCartItems();
    }, []);

    return (
        <CartContext.Provider value={{ cartCount, cartItems, addToCart, removeFromCart, cartTotal, fetchCartItems, setCartItems, fetchCartCount }}>
            {children}
        </CartContext.Provider>
    );
};
