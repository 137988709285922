import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
                   <div className='breadcrumb-item text-brown me-2'>Polcies</div>
          <div className='breadcrumb-item text-brown me-2'>1st September 2024</div>
          <div className='breadcrumb-item text-brown me-2'>Privacy Policy</div>
        </div>
      </div>
    );
  };

  return (
    <div className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={'Accessibility Policy'} />
      <Container className="privacy-policy">
        <Row>
          <Col>
            <h2>1. Introduction</h2>
            <p>Welcome to Hubibee ("we," "us," "our"). We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit our website and make purchases.</p>

            <h2>2. Information We Collect</h2>
            <p>We collect various types of information, including but not limited to:</p>
            <ul>
              <li><strong>Personal Information:</strong> This includes your name, contact details, and billing information.</li>
              <li><strong>Order Information:</strong> Details about the products you purchase, order history, and transaction records.</li>
              <li><strong>Device and Usage Information:</strong> Information about your device, browser, and how you interact with our website.</li>
              <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar technologies to enhance your experience and collect data about your browsing behavior.</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including but not limited to:</p>
            <ul>
              <li><strong>Processing Orders:</strong> To fulfill your orders, provide customer support, and manage returns or exchanges.</li>
              <li><strong>Personalization:</strong> To tailor our services and product recommendations to your preferences.</li>
              <li><strong>Analytics:</strong> To understand how you use our website and improve our offerings.</li>
              <li><strong>Communication:</strong> To keep you informed about your orders and provide updates.</li>
            </ul>

            <h2>4. Disclosure of Your Information</h2>
            <p>We may share your information with select third parties, including:</p>
            <ul>
              <li><strong>Shipping Partners:</strong> To facilitate the delivery of your orders (e.g., Professional Couriers).</li>
              <li><strong>Payment Gateway:</strong> We do not store card or payment information; transactions are securely processed through PhonePe Payment Gateway.</li>
              <li><strong>Legal Obligations:</strong> When required by law or in response to legal requests.</li>
            </ul>

            <h2>5. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar technologies for various purposes, such as:</p>
            <ul>
              <li><strong>Functionality:</strong> To remember your preferences and provide a seamless experience.</li>
              <li><strong>Analytics:</strong> To analyze website traffic and user behavior.</li>
              <li><strong>Advertising:</strong> To display relevant ads and track ad performance.</li>
            </ul>
            <p>You can manage your cookie preferences through your browser settings.</p>

            <h2>6. Data Security</h2>
            <p>We employ robust security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.</p>

            <h2>7. Third-Party Links</h2>
            <p>Our website may contain links to third-party websites. Please note that this Privacy Policy does not apply to external sites. We recommend reviewing the privacy policies of those websites.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions or concerns about our Privacy Policy or data practices, please contact us from the contact us page.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
