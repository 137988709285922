import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './MoreInfo.css';
import hive from '../../assets/images/hive.png';

const MoreInfo = () => {
    return (
        <div style={{ background: '#D9D9D9' }} className='py-0'>
            <Container className="my-5">
                <Row className="text-center">
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <img
                            src={hive}
                            alt="Hive"
                            className='hiveImage'
                        />                       <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-capitalise fw-bold text-brown">Flowers Produce Nectar and Attract Our Bees</h6>
                        </div>
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <img
                            src={hive}
                            alt="Hive"
                            className='hiveImage'
                        />                       <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-capitalise fw-bold text-brown">Bees Collect the Nectar and Carry It to The Hive</h6>
                        </div>
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <img
                            src={hive}
                            alt="Hive"
                            className='hiveImage'
                        />                       <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-capitalise fw-bold text-brown">Bees Seal Cells With Wax and Honey Ripens</h6>
                        </div>
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <img
                            src={hive}
                            alt="Hive"
                            className='hiveImage'
                        />                       <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-capitalise fw-bold text-brown">We Collect the Product and Transfer</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MoreInfo;
