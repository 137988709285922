import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import { Link } from 'react-router-dom';

const ExchangeReturnPolicy = () => {

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
                   <div className='breadcrumb-item text-brown me-2'>Polcies</div>
          <div className='breadcrumb-item text-brown me-2'>1st September 2024</div>
          <div className='breadcrumb-item text-brown me-2'>Exchange and Return Policy</div>
        </div>
      </div>
    );
  };

  return (
    <div className='sectionView mission-container pb-5'>
      <BreadcrumHeader route={Breadcrumb} title={'Exchange and Return Policy'} />
      <Container className="exchange-return-policy">
        <Row>
          <Col>
            <h2>1. No Returns or Exchanges</h2>
            <p>All sales made on Hubibee ("we," "us," "our") are considered final. We do not accept returns or exchanges through our website.</p>

            <h2>2. In-Person Returns</h2>
            <p>If you wish to return or exchange a product, please visit the nearest store for assistance. Store locations and contact information can be found on our website.</p>

            <h2>3. Defective or Damaged Products</h2>
            <p>If you receive a defective or damaged product, please contact our customer support team immediately after receiving the item. We will provide instructions on how to proceed.</p>

            <h2>4. Contact Us</h2>
            <p>If you have any questions or concerns about our Exchange and Return Policy, please contact us from the contact us page.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExchangeReturnPolicy;
