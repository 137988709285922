import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Form, InputGroup } from 'react-bootstrap';
import './StoreLocator.css';
import { useNavigate } from 'react-router-dom';

const StoreLocator = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);
  
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Function to fetch store data
    const fetchStores = async () => {
      try {
        const response = await axios.get('https://hubibee.com/api/client_api/stores/');
        setStores(response.data); // Assuming the response is in JSON format
        setFilteredStores(response.data); // Initialize with all stores
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    // Filter stores based on the search query
    const results = stores.filter(store =>
      store.stores_address.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredStores(results);
  }, [searchQuery, stores]);

  // Function to handle store selection and update map
  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  return (
    <Container fluid className='sectionView px-lg-5 px-md-5 px-sm-5 mt-3'>
      <div className='breadcrumb-container'>
        <div className='breadcrumb'>
          <a href='/' className='breadcrumb-item text-white'>Home</a>
          <div className='breadcrumb-item text-white'>Track Order</div>
        </div>
      </div>
      <h2 className='text-center text-white'>Find our nearest stores</h2>
      <Row className='pb-5 pt-2'>
        <Col sm={12} md={4}>
          <div className="store-search mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search stores by address..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="store-list">
            <ul className='p-0'>
              {filteredStores.length ? (
                filteredStores.map(store => (
                  <li
                    key={store.stores_id}
                    onClick={() => handleStoreClick(store)}
                    className={`store-item ${selectedStore?.stores_id === store.stores_id ? 'selected' : ''}`}
                  >
                    <img
                      src={`https://hubibee.com/${store.stores_img}`}
                      alt={store.stores_name}
                      className="store-image"
                    />
                    <div className="store-details">
                      <h5 className="store-name gradient-text">{store.stores_name}</h5>
                      <p className="store-address gradient-text">{store.stores_address}</p>
                    </div>
                  </li>
                ))
              ) : (
                <p>No stores found.</p>
              )}
            </ul>
          </div>
        </Col>
        <Col sm={12} md={8}>
          <div className="store-map h-100">
            {selectedStore ? (
              <iframe
                src={selectedStore.mapslink}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                className='rounded-3'
                loading="lazy"
              ></iframe>
            ) : (
              <p className='d-flex text-white align-item-center justify-content-center'>Select a store to see its location on the map.</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreLocator;
