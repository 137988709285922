import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Button, ButtonGroup, ButtonToolbar, Tabs, Tab, Card, Form, ListGroup, Accordion, ProgressBar, DropdownButton, Dropdown } from 'react-bootstrap';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import DOMPurify from 'dompurify';
import { IoAddSharp, IoArrowDown, IoAtCircle, IoCaretDown, IoCaretUp, IoCheckmarkCircle, IoChevronDown, IoChevronUp, IoCloudyNight, IoFemale, IoFlower, IoFootstepsOutline, IoFootstepsSharp, IoLeaf, IoLogoAppleAr, IoMale, IoMaleFemale, IoMan, IoMenu, IoPeople, IoPersonOutline, IoPersonSharp, IoRemoveSharp, IoSparkles, IoStar, IoStarHalf, IoStarOutline, IoStop, IoSunny, IoTimerOutline, IoTriangleSharp } from 'react-icons/io5';
import './Product.css';
import ImageViewer from "react-simple-image-viewer";
import { AiOutlineHome } from 'react-icons/ai';
import Marquee from 'react-fast-marquee';
import { TbHours24 } from 'react-icons/tb';
import Slider from 'react-slick';
import SecureLS from "secure-ls";
import SweetAlert2 from 'react-sweetalert2';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import ReactStars from 'react-rating-stars-component';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import Swal from 'sweetalert2'
import StarRating from '../../Utils/StarRating';
import OurProducts from '../../components/Products/OurProducts';

const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
        stars.push(<IoStar key={`full-${i}`} color='gold' />);
    }

    if (halfStar) {
        stars.push(<IoStarHalf key="half" color='gold' />);
    }

    for (let i = 0; i < emptyStars; i++) {
        stars.push(<IoStarOutline key={`empty-${i}`} />);
    }

    return stars;
};

const ProductDetail = () => {
    const navigate = useNavigate();
    const ls = new SecureLS({ encodingType: 'aes' });

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);
    const { productName } = useParams();
    const product_name = productName.replace(/-/g, ' ');
    const baseURL = 'https://hubibee.com/';

    const [item, setItem] = useState(null);
    const [Images, seImages] = useState([]);
    const [selectedSize, setSelectedSize] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);
    const [swalProps, setSwalProps] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [formData, setFormData] = useState({
        comment: '',
        rating: '',
        product_images: []
    });

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        username ? setIsLoggedIn(true) : setIsLoggedIn(false);
        fetchProduct(sessionId, username, product_name);
    }, [product_name]);

    const fetchProduct = async (sessionId, username, product_name) => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/products/getproductdetails/index.php`, {
                params: {
                    name: product_name,
                    email: username || 'na',
                    ipadd: sessionId || 'na'
                }
            });
            const fetchedItem = response.data.data[0];
            setItem(fetchedItem);
            seImages(fetchedItem.images);
            setSelectedSize(fetchedItem.price[0].weight + 'g'); // Default size
            setAllTestimonials(fetchedItem.review_and_ratings || []); // Save the full list of testimonials
            setTestimonials(fetchedItem.review_and_ratings || []);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const handleSizeChange = (size) => setSelectedSize(size);
    const handleQuantityChange = (change) => setQuantity(Math.max(1, quantity + change));
    // const handleThumbnailClick = (image) => setMainImage(baseURL + image);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const handleThumbnailClick = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item?.price.find(p => p.weight + 'g' === selectedSize);

        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails.prodcode, quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails.prodcode, quantity });
        if (success) {
            Swal.fire({
                title: "Success!",
                text: `${item?.name} has been added to your cart.`,
                icon: "success",
                cancelButtonText: "Continue Shopping",
                confirmButtonText: "Go to Cart",
                showCancelButton: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    // If confirmed, navigate to the cart page
                    navigate('/cart');
                }
            });
        } else {
            Swal.fire({
                title: "Failed!",
                text: `Error adding ${item?.name} to cart. Try again!`,
                icon: "error",
                showCloseButton: false,
            })
        }
    };

    const handleAddToWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item?.price.find(p => p.weight + 'g' === selectedSize);
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Added ${item?.name} to wishlist`);
        } else {
            console.error('Failed to add to wishlist');
        }
    };

    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item?.price.find(p => p.weight + 'g' === selectedSize);
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${item?.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html);
    };

    // Split the description into words and truncate to the first 200 words
    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(" ") + "...";
        }
        return text;
    };

    const truncatedDescription = item?.description ? truncateText(item?.description, 50) : '';


    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    const Discount = ((item?.price.find(p => p.weight + 'g' === selectedSize)?.basePrice - item?.price.find(p => p.weight + 'g' === selectedSize)?.discountedPrice) / item?.price.find(p => p.weight + 'g' === selectedSize)?.basePrice) * 100;
    const DiscountNearestPercentage = Math.round(Discount);

    const optionsMap = {
        "Sillage": {
            description: "The trail your perfume leaves behind",
            icon: <IoFootstepsOutline className='ms-1' />
        },
        "Projection": {
            description: "The reach of your scent",
            icon: <IoLogoAppleAr className='ms-1' />
        },
        "Longevity": {
            description: "How long your scent lasts",
            icon: <IoTimerOutline className='ms-1' />
        }
    };

    const notesIcons = {
        "top-notes": <IoCaretUp className='me-2' />,
        "middle-notes": <IoStop className='me-2' />,
        "bottom-notes": <IoCaretDown className='me-2' />
    };
    const [allTestimonials, setAllTestimonials] = useState([]); // To keep the original reviews
    const [testimonials, setTestimonials] = useState([]); // For filtered/sorted reviews
    const [filterRating, setFilterRating] = useState(null);
    const [sortBy, setSortBy] = useState('latest');
    const [showForm, setShowForm] = useState(false);

    // Function to filter reviews by rating
    const handleFilterByRating = (rating) => {
        setFilterRating(rating);
        let filteredReviews = allTestimonials; // Always start from allTestimonials

        if (rating) {
            filteredReviews = allTestimonials.filter((review) => Math.floor(review.rating) === rating);
        }

        // Apply the current sort to the filtered reviews
        handleSort(sortBy, filteredReviews);
    };

    // Modify handleSort to accept a reviews array (defaulting to testimonials)
    const handleSort = (option, reviews = testimonials) => {
        setSortBy(option);
        let sortedReviews;

        if (option === 'latest') {
            sortedReviews = [...reviews].sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (option === 'oldest') {
            sortedReviews = [...reviews].sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (option === 'highest') {
            sortedReviews = [...reviews].sort((a, b) => b.rating - a.rating);
        } else if (option === 'lowest') {
            sortedReviews = [...reviews].sort((a, b) => a.rating - b.rating);
        }

        setTestimonials(sortedReviews);
    };

    const RatingsCard = ({ item }) => (
        <Card className='h-100 me-2 mb-2 bg-transparent border-mute rounded-3' style={{ maxHeight: '18rem', minHeight: '10rem', }}>
            <Card.Body>
                <Card.Text className='text-start mb-1'>
                    {renderStars(item?.rating)}
                </Card.Text>
                <Card.Text className='text-start text-black mb-1'>{item?.name} <IoCheckmarkCircle color="green" />
                </Card.Text>
                <Card.Text className='text-start text-black mb-1'>{item?.comment}</Card.Text>
                {item?.product_images.map((img, index) => (
                    <img key={index} src={img} alt="Product" width="100" className="mr-2" />
                ))}
            </Card.Body>
        </Card>
    );

    const handleShowForm = () => {
        setShowForm(true);
    }

    const handleCloseForm = () => {
        setShowForm(false);
    }

    const handleLogin = () => {
        navigate('/auth/login');  // Navigate to the login route
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 3) {
            alert("You can only upload up to 3 images");
            return;
        }

        const validFiles = files.filter(file => file.size <= 1024 * 1024); // 1MB limit
        if (validFiles.length !== files.length) {
            alert("Some images are larger than 1MB");
            return;
        }

        setFormData({
            ...formData,
            product_images: validFiles
        });
    };

    const handleRatingChange = (newRating) => {
        setFormData({ ...formData, rating: newRating });  // Only updates rating, doesn't re-render whole component
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const username = ls.get('userEmail');

        const updatedFormData = {
            ...formData,
            username: username,
            productId: item?.id
        };

        // console.log("Form submitted", updatedFormData);

        axios.post('https://hubibee.com/api/client_api/products/rateProducts/', updatedFormData)
            .then(response => {
                // console.log(response.data);

                if (response.data.status == 'success') {
                    // Clear form data
                    setFormData({
                        // Reset form fields to their initial values
                        comment: '',
                        rating: '',
                        product_images: [] // Adjust this based on how you store images
                    });

                    const sessionId = sessionStorage.getItem('sessionId');
                    const username = ls.get('userEmail');
                    fetchProduct(sessionId, username, product_name);
                } else {
                    alert('Error submitting a review!')
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const Breadcrumb = () => {
        // Extract collection name and product name
        const collections = JSON.parse(item?.collection);
        const excludedCollections = ["All Products", "Attars", "Unisex", "Men", "Women", "For Her", "For Him"];
        const filteredCollections = collections.filter(collection => !excludedCollections.includes(collection));
        const collectionName = filteredCollections.length > 0 ? filteredCollections[0] : 'All Products';
        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb mb-0 px-3'>
                    <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
                    <div className='breadcrumb-item text-brown me-2'>Collections</div>
                    <Link to={`/collection/${generateSlug(collectionName)}`} className='breadcrumb-item text-brown'>{collectionName}</Link>
                </div>
            </div>
        );
    };

    const createHexagonGroups = () => {
        const groups = [];

        for (let i = 0; i < Images.length; i += 4) {
            const group = (
                <div className="hexagon-product-img-wrapper" key={i}>
                    {/* First Row */}
                    <div className="hexagon-product-img-list clearfix">
                        {Images.slice(i, i + 2).map((image, index) => (
                            <div className="hexagon-product-img" key={index}>
                                <img src={baseURL + image} className='w-100' />
                            </div>
                        ))}
                    </div>
                    {/* Second Row */}
                    <div className="hexagon-product-img-list clearfix">
                        {Images.slice(i + 2, i + 4).map((image, index) => (
                            <div className="hexagon-product-img" key={index}>
                                <img src={baseURL + image} className='w-100' />
                            </div>
                        ))}
                    </div>
                </div>
            );
            groups.push(group);
        }

        return groups;
    };

    return (
        <section className='sectionView'>
            <BreadcrumHeader route={item == null ? '' : Breadcrumb} title={item?.name} />
            <Container>
                <Row className="my-4">
                    {/* only for non mobile */}
                    <Col md={12} lg={6} sm={12}>
                        <div style={{ position: '-webkit-sticky', position: 'sticky', top: '10vh' }}>
                            {createHexagonGroups()}
                        </div>
                    </Col>
                    <Col md={12} lg={6} sm={12}>
                        <div style={{ position: 'sticky', top: '10vh' }}>
                            <h2 className='text-brown fw-bold fs-1'>{item?.name}</h2>
                            {item?.price.find(p => p.weight + 'g' === selectedSize)?.average_rating > 0 &&
                                <div className='d-flex'>
                                    <StarRating rating={String(item?.price.find(p => p.weight + 'g' === selectedSize)?.average_rating)} />
                                    <div className="rating text-black  fw-bold fs-6 ms-2">{String(item?.price.find(p => p.weight + 'g' === selectedSize)?.average_rating)}/5</div>
                                </div>
                            }
                            <span className={`text-${item?.price.find(p => p.weight + 'g' === selectedSize)?.inStock ? 'success' : 'danger'}`}>
                                {item?.price.find(p => p.weight + 'g' === selectedSize)?.inStock ? 'In stock' : 'Out of stock'}
                            </span>
                            <div className='d-flex mt-2'>
                                <h4 className='text-black me-2 fw-bold fs-3'>₹ {item?.price.find(p => p.weight + 'g' === selectedSize)?.discountedPrice || item?.price[0].discountedPrice}
                                    {/* <span className="badge abstext-danger text-danger ms-2 rounded-5" style={{ background: '#ff333352' }}>{DiscountNearestPercentage}%</span> */}
                                </h4>
                                <span className="text-muted text-decoration-line-through fs-5">Rs {item?.price.find(p => p.weight + 'g' === selectedSize)?.basePrice || item?.price[0].basePrice}</span>
                            </div>
                            <p
                                className='text-brown'
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(truncatedDescription)
                                }}
                            ></p>
                            <hr className='' />
                            <ButtonToolbar className="mb-4">
                                {item?.price.map((p, index) => (
                                    <Button
                                        key={index}
                                        variant={selectedSize === p.weight + 'g' ? 'light' : 'outline-light'}
                                        onClick={() => handleSizeChange(p.weight + 'g')}
                                        className={`mx-1 rounded-5 ${selectedSize === p.weight + 'g' ? 'bg-brown' : 'bg-yellow'}`}
                                    >
                                        {p.weight}g
                                    </Button>
                                ))}
                            </ButtonToolbar>

                            <div className="quantity-selector mb-3 d-flex justify-content-between">
                                <ButtonGroup style={{ flexBasis: '33%' }}>
                                    <Button className='bg-brown text-white border-0 rounded-start-5' onClick={() => handleQuantityChange(-1)}>
                                        <IoRemoveSharp />
                                    </Button>
                                    <span className="align-self-center bg-brown text-white px-4 py-2 fw-bold">{quantity}</span>
                                    <Button className='bg-brown text-white border-0 rounded-end-5' onClick={() => handleQuantityChange(1)}>
                                        <IoAddSharp />
                                    </Button>
                                </ButtonGroup>
                                <Button style={{ flexBasis: '65%' }} disabled={item?.price.find(p => p.weight + 'g' === selectedSize)?.inStock ? false : true} block variant="outline-light" className='rounded-5 bg-yellow' onClick={handleAddToCart}>Add to Cart</Button>
                            </div>
                            <Link to={item?.price.find(p => p.weight + 'g' === selectedSize)?.inStock ? '/checkout' : ''} state={{ item: item, isBuyNow: 'no', isCart: 'yes', selectedSize: selectedSize }} >
                                <Button disabled={item?.price.find(p => p.weight + 'g' === selectedSize)?.inStock ? false : true} block variant="light" className='rounded-5 w-100 bg-brown'>Buy Now</Button>
                            </Link>
                            <hr className='' />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Tabs defaultActiveKey="details" id="product-tabs" className="container px-lg-4 px-md-4 px-sm-4 mb-3 mt-5 d-flex justify-content-around align-items-center custom-tabs" variant="underline" >
                <Tab eventKey="details" title="Product Details" className='container px-lg-4 px-md-4 px-sm-4'>
                    <p className='text-brown' dangerouslySetInnerHTML={{ __html: sanitizeHTML(item?.description) }} ></p>
                </Tab>
                <Tab eventKey="reviews" title="Rating & Reviews" className='container px-lg-4 px-md-4 px-sm-4'>
                    <div className="d-flex justify-content-between">
                        <h5 className='fw-bold'>All Reviews ({allTestimonials.length})</h5>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <DropdownButton
                                    as={ButtonGroup}
                                    variant={'secondary'}
                                    title={'Sort By'}
                                    className='rounded-5'
                                    onSelect={(e) => handleSort(e)} // Use onSelect instead of onChange
                                >
                                    <Dropdown.Item eventKey="latest">Latest</Dropdown.Item>  {/* Use string keys for sorting */}
                                    <Dropdown.Item eventKey="oldest">Oldest</Dropdown.Item>
                                    <Dropdown.Item eventKey="highest">Highest Rating</Dropdown.Item>
                                    <Dropdown.Item eventKey="lowest">Lowest Rating</Dropdown.Item>
                                </DropdownButton>
                            </div>

                            <div>
                                <DropdownButton
                                    as={ButtonGroup}
                                    variant={'secondary'}
                                    className='me-2'
                                    title={'By Rating'}
                                    onSelect={(e) => handleFilterByRating(Number(e))}  // Use onSelect instead of onChange and convert eventKey to number
                                >
                                    <Dropdown.Item eventKey="5">5 Stars</Dropdown.Item>
                                    <Dropdown.Item eventKey="4">4 Stars</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">3 Stars</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">2 Stars</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">1 Star</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div>
                                <Button block variant="outline-dark" className='rounded-5 w-100 bg-yellow' onClick={isLoggedIn ? (showForm ? handleCloseForm : handleShowForm) : handleLogin}>{isLoggedIn ? (showForm ? 'Cancel' : 'Write a Review') : 'Login'}</Button>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col lg={(isLoggedIn && showForm) ? 8 : 12}>
                            <Row>
                                <Col lg={6} sm={6} md={6}>
                                    {testimonials.map((testimonial, index) => (
                                        <RatingsCard item={testimonial} key={index} />
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                        {(isLoggedIn && showForm) &&
                            <Col md={4} >
                                <>
                                    <h3 className='text-brown mt-5'>Submit a Review</h3>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formRating">
                                            <Form.Label className="text-brown">Rating</Form.Label>
                                            <ReactStars
                                                count={5}
                                                onChange={handleRatingChange}
                                                size={34}
                                                isHalf={true}
                                                emptyIcon={<IoStarOutline />}
                                                halfIcon={<IoStarHalf color='gold' />}
                                                fullIcon={<IoStar color='gold' />}
                                                activeColor="#ffd700"
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formComment">
                                            <Form.Label className='text-brown'>Comment</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={formData.comment}
                                                onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
                                                required
                                            />
                                        </Form.Group>
                                        <Button variant="primary" className='bg-white text-black border-0 rounded-5 w-50 mt-3' type="submit">
                                            Submit Review
                                        </Button>
                                    </Form>
                                </>
                            </Col>
                        }
                    </Row>
                </Tab>
                <Tab eventKey="faqs" title="FAQs">
                    {/* FAQs content */}
                </Tab>
            </Tabs>
            <RecentlyAdded />
            <OurProducts />
        </section>
    );
};

export default ProductDetail;
