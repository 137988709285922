import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'; // Add Button from react-bootstrap
import './Products.css';
import ProductCard from '../ProductCard/ProductCard';
import { Link } from 'react-router-dom';

function RecentlyAdded() {
    const [loading, setLoading] = useState(true);
    const baseUrl = 'https://hubibee.com/';
    const [recentlyAdded, setRecentlyAdded] = useState([]);

    useEffect(() => {
        getFragrances();
    }, []);

    const getFragrances = async () => {
        try {
            const response = await axios.get('https://hubibee.com/api/client_api/RecentlyAdded/index.php');
            setRecentlyAdded(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Container className='px-lg-0 px-md-0 px-sm-0 px-0'>
                <section className='pt-3'>
                    <Row className="g-0">
                        <Col>
                            <div className='d-flex justify-content-between align-items-center px-lg-4 px-md-5 px-sm-5 px-4'>
                                <div>
                                    <h4 className='title text-black py-2 text-capitalise'>
                                        Recently <span className='gradient-text'>Added</span>
                                    </h4>
                                </div>
                            </div>
                            {loading ? (
                                <div className='px-lg-5 px-md-5 px-sm-5 px-4 pt-4'>Loading...</div>
                            ) : (
                                <div style={{ position: 'relative' }} className='px-lg-4 px-md-4 px-sm-4 px-4 pt-4'>
                                    <Row>
                                        {recentlyAdded.map((item, index) => (
                                            <Col md={6} sm={12} lg={3} key={index} className='p-0'> {/* added key to Col to avoid warning */}
                                                <ProductCard
                                                    item={item}
                                                    baseUrl={baseUrl}
                                                    wishlist={item.in_wishlist}
                                                    cart={item.in_cart}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                    {/* View All Button */}
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button
                                            className="btn px-4 yellow-btn fw-bold"
                                            as={Link}
                                            to="/collections"
                                        >
                                            View All
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default RecentlyAdded;
