import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, ListGroup, Badge, ButtonGroup, Form, InputGroup, FormControl } from 'react-bootstrap';
import './Cart.css';
import { IoAddSharp, IoArrowForward, IoRemoveSharp, IoStar, IoTrashBin } from 'react-icons/io5';
import { WishlistContext } from '../../Utils/WishlistContext';
import { CartContext } from '../../Utils/CartContext';
import NoCartItems from './NoCartItems';
import { Link, useNavigate } from 'react-router-dom';
import razorPay from '../../assets/images/razorpay.png';
import { AiOutlineArrowRight, AiOutlineDelete, AiOutlineHome, AiOutlineLoading3Quarters, AiOutlineTag } from 'react-icons/ai';
import { TbHeartCheck, TbHeartPlus } from 'react-icons/tb';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import OurProducts from '../../components/Products/OurProducts';
import Subscribe from '../../components/Subscribe/Subscribe';
import SecureLS from "secure-ls";
import RelatedProducts from '../../components/Products/RelatedProducts';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';

function Cart() {
  const baseUrl = 'https://hubibee.com/';
  const ls = new SecureLS({ encodingType: 'aes' });
  const navigate = useNavigate();

  const { removeFromCart, addToCart, cartItems, setCartItems, fetchCartItems } = useContext(CartContext);
  // const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);
  const [loadingStates, setLoadingStates] = useState({}); // To track loading states for each item

  const handleIncreaseQuantity = async (item) => {
    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');
    const selectedPriceDetails = item.price.prodcode;
    const quantity = 1;
    const success = username
      ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails, quantity })
      : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails, quantity });

    if (success) {
      // console.log(`Added ${item.name} to cart`);
      // fetchCartItems();
    } else {
      console.error('Failed to add to cart');
    }
  };

  const handleDecreaseQuantity = async (item) => {
    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');
    const selectedPriceDetails = item.price.prodcode;
    const quantity = 1;
    const success = username
      ? await removeFromCart('na', username, { selectedProdCode: selectedPriceDetails, quantity })
      : await removeFromCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails, quantity });

    if (success) {
      // console.log(`Removed ${item.name} to cart`);
      fetchCartItems();
    } else {
      console.error('Failed to Remove from cart');
    }
  };

  const handleRemoveItem = async (item) => {
    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');
    const success = await removeFromCart(sessionId, username, item);
    if (success) {
      setCartItems(prevItems => prevItems.filter(cartItem => cartItem.id !== item.id));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior:'instant' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);


  // Calculate totals
  const totalQuantity = cartItems.reduce((total, item) => total + parseInt(item.qty), 0);
  const totalMRP = cartItems.reduce((total, item) => total + parseFloat(item.price.basePrice) * parseInt(item.qty), 0);
  const totalDiscount = cartItems.reduce((total, item) => total + (parseFloat(item.price.basePrice) - parseFloat(item.price.discountedPrice)) * parseInt(item.qty), 0);
  const totalAmount = cartItems.reduce((total, item) => total + parseFloat(item.price.discountedPrice) * parseInt(item.qty), 0);

  const [promoCode, setPromoCode] = useState('');

  const handleApplyPromoCode = () => {
    console.log(promoCode);
  };


  const gotoCheckout = () => {
    navigate('/checkout', { state: { item: cartItems, isBuyNow: 'no', isCart: 'yes' } });
  };

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
          <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
          <div className='breadcrumb-item text-brown me-2'>cart</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <section className='sectionView'>
        <BreadcrumHeader route={Breadcrumb} title={'My Cart'} />
        <Container>
          {cartItems.length > 0 ? (
            <Row className='d-flex justify-content-center'>
              <Col md={7}>
                <Card className='mb-2 rounded-4 bg-transparent' style={{ position: 'sticky', top: '18vh' }}>
                  {cartItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <Card.Body>
                        <Row className="d-flex align-items-start">
                          <Col xs={6} s={6} md={6} lg={4} className="me-0">
                            <img src={baseUrl + item.images[0]} alt={item.name} className='w-100 h-75 rounded' />
                          </Col>
                          <Col xs={6} s={6} md={6} lg={8} className="d-flex flex-column align-self-center overflow-hidden">
                            <div>
                              <h5 className="text-truncate text-brown text-start fs-5 fw-bold mb-1">
                                <a href="#" className="text-brown">{item.name}</a>
                              </h5>
                              <p className='price-before me-2 mb-0 text-small text-start d-flex align-items-center'>
                                gram: {item?.price.weight} g
                              </p>
                              <div>
                                <div className='w-100'>
                                  <div>
                                    <p className='prod-price text-small fs-6 me-2 mb-0 fw-light text-start text-decoration-line-through'>
                                      Rs {item?.price.basePrice}
                                    </p>
                                    <Row>
                                      <Col>
                                        <p className='prod-price text-small fs-5 mb-0 fw-bold text-start'>
                                          Rs {item?.price.discountedPrice}
                                        </p>
                                      </Col>
                                      <Col>
                                        <div className="quantity-selector mb-3 d-flex justify-content-end">
                                          <Button onClick={() => handleDecreaseQuantity(item)} className='bg-brown border-0 rounded-start-5 rounded-end-0'>
                                            <IoRemoveSharp color='white' />
                                          </Button>
                                          <span className="align-self-center text-white bg-brown px-4 py-2 fw-bold">{item.qty}</span>
                                          <Button onClick={() => handleIncreaseQuantity(item)} className='bg-brown border-0 rounded-end-5 rounded-start-0'>
                                            <IoAddSharp color='white' />
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <div className='mx-3'>
                        {index < cartItems.length - 1 && <hr />} {/* Add <hr> except for the last item */}
                      </div>
                    </React.Fragment>
                  ))}

                </Card>
              </Col>
              <Col md={5}>
                <Card className='mb-2 rounded-4 bg-transparent' style={{ position: 'sticky', top: '18vh' }}>
                  <Card.Body>
                    <h3 className='text-start text-brown fw-bold fs-4 pb-2'>Order Summary</h3>
                    <Row>
                      <Col className='text-start'>Quantity</Col>
                      <Col className="text-end fs-5 fw-bold">{totalQuantity} item{totalQuantity > 1 ? 's' : ''}</Col>
                    </Row>
                    <Row>
                      <Col className='text-start'>Subtotal</Col>
                      <Col className="text-end fs-5 fw-bold">₹ {totalMRP.toFixed(2)}</Col>
                    </Row>
                    <Row>
                      <Col className='text-start'>Discount</Col>
                      <Col className="text-end  fs-5 fw-bold">₹ {totalDiscount.toFixed(2)}</Col>
                    </Row>
                    <hr />
                    <Row className="mt-3">
                      <Col className='text-start'>Total</Col>
                      <Col className="text-end  fs-5 fw-bold"><strong>₹ {totalAmount.toFixed(2)}</strong></Col>
                    </Row>
                    {/* <hr />
                    <h5 className='text-start fr1'>Payment Method</h5>
                    <p className='text-start fs-6'>All transactions are secure and encrypted.</p>
                    <Card className="my-3 p-3">
                      <Card.Title className='fr1'>Razorpay Secure (UPI, Cards, Wallets, NetBanking)</Card.Title>
                      <Card.Text>
                        <img src={razorPay} alt="Razorpay" style={{ width: '100%' }} />
                      </Card.Text>
                    </Card> */}
                    {/* <Link to={`/checkout`} className='bg-dark w-100 rounded-pill py-3 text-center'>
                      
                    </Link> */}
                    <Button variant="dark" className='rounded-5 w-100 py-3 mt-5 justify-content-center bg-yellow text-brown fw-bold' onClick={gotoCheckout}>Go to Checkout <IoArrowForward className='ms-2' /></Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <NoCartItems />
          )}
        </Container>
        <RelatedProducts />
        <RecentlyAdded />
      </section>
    </div>
  );
}

export default Cart;
