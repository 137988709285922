import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import SecureLS from "secure-ls";
import CryptoJS from 'crypto-js';
import BreadcrumHeader from '../../../Utils/BreadcrumHeader';
function Reset() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false); // Show/hide password toggle state
    const navigate = useNavigate();
    const ls = new SecureLS({ encodingType: 'aes' });
    const [loading, setLoading] = useState(true);
    // Extract the success message from the location state
    const [successMessage, setSuccessMessage] = useState(location.state?.successMessage);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            try {
                const response = await axios.post('https://hubibee.com/api/client_api/authentication/reset/index.php', {
                    email,
                });

                if (response.data.success) {
                    setSuccessMessage('A password reset link has been sent to your email. Please check your inbox.');
                    setError('');
                } else {
                    setError(response.data.error || 'Email not found. Please check and try again.');
                    setSuccessMessage('');
                }
            } catch (err) {
                console.error('Password reset request failed:', err);
                setError('An error occurred. Please try again later.');
            }
        }

        setValidated(true);
    };

    const handleLoginPage = () => {
        navigate('/auth/login');
    };

    const Breadcrumb = () => {
        // Extract collection name and product name
        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb mb-0 px-3'>
                    <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
                    <div className='breadcrumb-item text-brown me-2'>Authentication</div>
                    <Link to={`/auth/login`} className='breadcrumb-item text-brown'>Reset Password</Link>
                </div>
            </div>
        );
    };


    return (
        <div className='sectionView' style={{ minHeight: '100vh' }}>
            <BreadcrumHeader route={Breadcrumb} title={'Reset Password'} />
            <Container className="d-flex justify-content-center align-items-center">
                <Row className="w-100">
                    <Col md={6} className="mx-auto">
                        <Card className='bg-transparent border-0'>
                            <Card.Body className='bg-transparent border-0 rounded    h-100 p-lg-5 py-5 '>
                                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                {error && <Alert variant="danger">{error}</Alert>}

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            required
                                            className='rounded-5'
                                            type="email"
                                            id="email"
                                            placeholder="Enter email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Form.Label htmlFor="email">Email address</Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Floating>

                                    <Button variant="dark" type="submit" className="bg-yellow rounded-5 text-center py-3 w-100">
                                        Send Reset Link
                                    </Button>

                                    <Button variant="outline-secondary" className="rounded-5 text-center py-3 w-100 mt-3" onClick={handleLoginPage}>
                                        Back to Login
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Reset;
